<template>
  <div class="footer">
    <div class="container columns is-vcentered is-mobile">
      <div class="column byline">
        © {{ instanceName }} | Directory by <a href="https://datajoe.com" target="_blank" rel="noopener" class="is-underlined">DataJoe</a>
      </div>
      <div class="column is-2 is-hidden-mobile is-hidden-tablet-only" />
      <div class="column has-text-right">
        <a href="https://datajoe.com" target="_blank" rel="noopener">
          <img
            src="https://s3-us-west-2.amazonaws.com/directory.covamd.com/poweredby.png"
            class="is-powered-by"
            alt="Powered by DataJoe"
          >
        </a>
        <div class="buttons m-0 is-inline-block">
          <Sharing />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Sharing from '@/components/Global/Sharing.vue';

export default {
  computed: {
    ...mapState({
      instanceName: (state) => state.name,
    }),
  },
  components: {
    Sharing,
  },
};
</script>
