export const namespaced = true;

function filterStaticAds(ads) {
  // shuffle
  // eslint-disable-next-line no-plusplus
  for (let i = ads.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    // eslint-disable-next-line no-param-reassign
    [ads[i], ads[j]] = [ads[j], ads[i]];
  }

  // validate
  return ads.filter((ad) => !!(ad.src && ad.link));
}

export const state = {
  provider: '',
  results_ads: [],
  leaderboard_ads: [],
  record_ads: [],
  search_banner_ads: [],
  landing_ads: [],
};

export const mutations = {
  RESET_ALL_ADS(state) {
    state.results_ads = [];
    state.leaderboard_ads = [];
    state.record_ads = [];
    state.search_banner_ads = [];
    state.landing_ads = [];
  },
  SET_PROVIDER(state, provider) {
    state.provider = provider;
  },
  SET_RESULTS_ADS(state, ads) {
    state.results_ads = ads;
  },
  SET_LEADERBOARD_ADS(state, ads) {
    state.leaderboard_ads = ads;
  },
  SET_RECORD_ADS(state, ads) {
    state.record_ads = ads;
  },
  SET_SEARCH_BANNER_ADS(state, ads) {
    state.search_banner_ads = ads;
  },
  SET_LANDING_ADS(state, ads) {
    state.landing_ads = ads;
  },
};

export const actions = {
  get({ commit, rootState }) {
    commit('SET_PROVIDER', rootState.ads.provider);

    // ADVERTSERVE PROVIDER
    if (rootState.ads.provider === 'advertserve') {
      commit('RESET_ALL_ADS');
      if (rootState.ads.leaderboard_ads) {
        fetch(`${rootState.ads.leaderboard_ads}&referrer=${window.location.href}`)
          .then((response) => response.json())
          .then((data) => commit('SET_LEADERBOARD_ADS', data.tags))
          .catch((err) => console.debug(err));
      }

      if (rootState.ads.record_ads) {
        fetch(`${rootState.ads.record_ads}&referrer=${window.location.href}`)
          .then((response) => response.json())
          .then((data) => commit('SET_RECORD_ADS', data.tags))
          .catch((err) => console.debug(err));
      }

      if (rootState.ads.results_ads) {
        fetch(`${rootState.ads.results_ads}&referrer=${window.location.href}`)
          .then((response) => response.json())
          .then((data) => commit('SET_RESULTS_ADS', data.tags))
          .catch((err) => console.debug(err));
      }

      if (rootState.ads.search_banner_ads) {
        fetch(`${rootState.ads.search_banner_ads}&referrer=${window.location.href}`)
          .then((response) => response.json())
          .then((data) => commit('SET_SEARCH_BANNER_ADS', data.tags))
          .catch((err) => console.debug(err));
      }

      if (rootState.ads.landing_ads) {
        fetch(`${rootState.ads.landing_ads}&referrer=${window.location.href}`)
          .then((response) => response.json())
          .then((data) => commit('SET_LANDING_ADS', data.tags))
          .catch((err) => console.debug(err));
      }
    }

    // SCRIPT PROVIDER
    if (rootState.ads.provider === 'script') {
      commit('SET_LEADERBOARD_ADS', rootState.ads?.leaderboard_ads ?? '');
      commit('SET_RECORD_ADS', rootState.ads?.record_ads ?? '');
      commit('SET_RESULTS_ADS', rootState.ads?.results_ads ?? '');
      commit('SET_SEARCH_BANNER_ADS', rootState.ads?.search_banner_ads ?? '');
      commit('SET_LANDING_ADS', rootState.ads?.landing_ads ?? '');
    }

    // STATIC PROVIDER
    if (rootState.ads.provider === 'static') {
      const leaderboard_ads = filterStaticAds(rootState.ads.leaderboard_ads);
      commit('SET_LEADERBOARD_ADS', leaderboard_ads);

      const record_ads = filterStaticAds(rootState.ads.record_ads);
      commit('SET_RECORD_ADS', record_ads);

      const results_ads = filterStaticAds(rootState.ads.results_ads);
      commit('SET_RESULTS_ADS', results_ads);

      const search_banner_ads = filterStaticAds(rootState.ads.search_banner_ads);
      commit('SET_SEARCH_BANNER_ADS', search_banner_ads);

      const landing_ads = filterStaticAds(rootState.ads.landing_ads);
      commit('SET_LANDING_ADS', landing_ads);
    }
  },
};
